import { createColumnHelper } from '@tanstack/table-core';
import { format } from 'date-fns';
import React, { useMemo } from 'react';
import Loader from '../../../components/Loader';
import Table from '../../../components/Table/Table';
import { useSnackbar } from '../../../context/SnackbarContext';
import { groupByProperty } from '../../../helpers/utils';
import { useTimeSheetsByWeek } from '../../../services/timesheets';

export default function Hours({ week }) {
  const { showSnackbar } = useSnackbar();

  const columnHelper = createColumnHelper();
  const columns = useMemo(
    () => [
      columnHelper.accessor('user', {
        cell: (info) => info.getValue(),
        header: 'Consultor',
      }),
      columnHelper.accessor('client', {
        cell: (info) => info.getValue(),
        header: 'Cliente',
      }),
      columnHelper.accessor('project', {
        cell: (info) => info.getValue(),
        header: 'Proyecto',
      }),
      columnHelper.accessor('hours', {
        cell: (info) => info.getValue(),
        header: 'Horas',
      }),
    ],
    [],
  );

  const exportProperties = useMemo(
    () => ({
      fileName: `Junta_Semanal_Horas_${format(week, 'yyyydd')}`,
      fields: [
        { label: 'Consultor', value: 'user' },
        { label: 'Cliente', value: 'client' },
        { label: 'Proyecto', value: 'project' },
        { label: 'Horas', value: 'hours' },
      ],
    }),
    [],
  );

  const [timeSheets, loading, error] = useTimeSheetsByWeek(week);

  if (error) {
    console.error(error);
    showSnackbar('Hubo un error, intentalo mas tarde.', 'error');
  }

  const parsedTimeSheets = timeSheets.map((timeSheet) => ({
    user: timeSheet.user.name,
    client: timeSheet.project.client,
    project: timeSheet.project.name,
    hours: timeSheet.hours,
  }));

  const data = groupByProperty(
    parsedTimeSheets,
    ['project', 'client', 'user'],
    'hours',
  );

  return (
    <>
      {loading ? <Loader /> : null}
      <Table
        data={data}
        columns={columns}
        enableExport
        exportProperties={exportProperties}
      />
    </>
  );
}
