import { buttonClasses, createTheme } from '@mui/material';
import { esES } from '@mui/x-data-grid';
import { esES as coreEsES } from '@mui/material/locale';

const defaultTheme = createTheme();
const theme = createTheme(
  {
    typography: {
      h4: {
        color: '#000044',
        fontWeight: 'bold',
        margin: `${defaultTheme.spacing(2)} 0`,
      },
      h5: {
        color: '#000044',
        fontWeight: 'bold',
      },
      h6: {
        color: '#000044',
        fontWeight: 'bold',
      },
    },
    palette: {
      primary: {
        main: '#0F2870',
      },
      secondary: {
        main: '#EC2F23',
      },
      action: {
        disabledBackground: '',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            [`&.${buttonClasses.disabled}`]: {
              color: 'white',
              opacity: 0.6,
            },
            [`&.${buttonClasses.disabled} .MuiLoadingButton-loadingIndicator`]:
              {
                color: 'white',
              },
          },
        },
      },
    },
  },
  esES,
  coreEsES,
);

export default theme;
