export const StatusLabel = {
  green: 'Verde',
  yellow: 'Amarillo',
  red: 'Rojo',
  blue: 'Azul',
  grey: 'Gris',
};

export const StatusSeverity = {
  red: 4,
  yellow: 3,
  green: 2,
  blue: 1,
  grey: 0,
};
