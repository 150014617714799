import { Parser } from '@json2csv/plainjs';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';
import { downloadCSV } from '../../helpers/utils';
import DebouncedInput from '../DebouncedInput';
import Icon from '../Icon';
import './Table.css';

export default function Table({
  data,
  columns,
  enableExport = false,
  enableSorting = false,
  enableFilter: enableGlobalFilter = false,
  exportProperties,
}) {
  const [globalFilter, setGlobalFilter] = useState('');

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    initialState: {
      pagination: {
        pageSize: 25,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSorting,
    enableGlobalFilter,
  });

  const handleExport = () => {
    const parser = new Parser({
      fields: exportProperties?.fields,
      withBOM: true,
    });

    const { rows } = table.getFilteredRowModel();
    const tableData = rows.map((row) => row.original);
    const csvData = parser.parse(tableData);

    downloadCSV(csvData, exportProperties.fileName);
  };

  return (
    <>
      {enableGlobalFilter ? (
        <label
          className="table__filter label label--vertical"
          htmlFor="globalFilter"
        >
          Buscar
          <DebouncedInput
            className="input"
            type="text"
            id="globalFilter"
            value={globalFilter}
            onChange={(value) => setGlobalFilter(value)}
          />
        </label>
      ) : null}
      <div className="table__container">
        <table className="table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    className={header.column.getCanSort() ? 'sortable' : ''}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: ' ▲',
                          desc: ' ▼',
                        }[header.column.getIsSorted()] ?? null}
                      </>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td className="table__placeholder" colSpan={columns.length}>
                  <p>
                    <Icon>description</Icon>
                  </p>
                  No se encontraron registros
                </td>
              </tr>
            ) : (
              table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="table__footer">
        <div className="table__options">
          {enableExport && table.getFilteredRowModel().rows.length > 0 ? (
            <button
              type="button"
              className="button button--action"
              onClick={handleExport}
              data-tooltip="Exportar"
            >
              <Icon>file_download</Icon>
            </button>
          ) : null}
        </div>
        <div className="table__pagination">
          <label className="label" htmlFor="pageSize">
            Filas por página:
            <select
              className="select"
              name="pageSize"
              id="pageSize"
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[25, 50, 100].map((pageSize) => (
                <option key={`${pageSize}_pages`} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </label>
          <div className="table__controls">
            <button
              type="button"
              className="table__button"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <Icon>keyboard_double_arrow_left</Icon>
            </button>
            <button
              type="button"
              className="table__button"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <Icon>keyboard_arrow_left</Icon>
            </button>
            <span className="table__current-page">
              {table.getPageCount() > 0
                ? table.getState().pagination.pageIndex + 1
                : 0}{' '}
              de {table.getPageCount()}
            </span>
            <button
              type="button"
              className="table__button"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <Icon>keyboard_arrow_right</Icon>
            </button>
            <button
              type="button"
              className="table__button"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <Icon>keyboard_double_arrow_right</Icon>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
