import DeleteIcon from '@mui/icons-material/Delete';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { DatePicker } from '@mui/x-date-pickers';
import {
  add,
  eachDayOfInterval,
  endOfWeek,
  format,
  getMonth,
  getYear,
  isMonday,
  isWeekend,
  nextMonday,
  previousMonday,
  startOfWeek,
} from 'date-fns';
import { es } from 'date-fns/locale';
import { Form, Formik } from 'formik';
import { uniqWith } from 'lodash';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useAuth } from '../../../context/AuthContext';
import { useLoading } from '../../../context/LoadingContext';
import { useSnackbar } from '../../../context/SnackbarContext';
import { Roles } from '../../../models/user-roles.enum';
import { getUserProjects } from '../../../services/projects';
import {
  getUserTimeSheets,
  setTimeSheets,
  deleteRow,
} from '../../../services/timesheets';
import { getDependentUsers } from '../../../services/users';

const validationSchema = yup.object({
  consultant: yup.string().required(),
  searchProject: yup.string().required(),
  days: yup.string().required(),
});

export default function HoursRecord() {
  const { currentUser } = useAuth();
  const { showSnackbar } = useSnackbar();
  const { setLoading } = useLoading();

  const [users, setUsers] = useState([]);
  const initialValues = {
    user: { uid: currentUser.uid, name: currentUser.displayName },
  };
  const [week, setWeek] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 }),
  );
  const [employee, setEmployee] = useState(false);
  const [empName, setEmpName] = useState(false);
  const [rows, setRows] = useState([]);
  const [projects, setProjects] = useState([]);
  const [autocompleteValue, setAutocompleteValue] = useState('');

  const formattedDate = format(week, 'PPP', { locale: es });

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        if (currentUser.role !== Roles.CONSULTANT) {
          const dependentUsers = await getDependentUsers(currentUser);
          setUsers(
            uniqWith(
              [
                { uid: currentUser.uid, name: currentUser.displayName },
                ...dependentUsers,
              ],
              (a, b) => a.uid === b.uid,
            ),
          );
        }
      } catch (error) {
        console.error(error);
        showSnackbar('Hubo un error, intentalo mas tarde.', 'error');
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  const daysOfWeek = eachDayOfInterval({
    start: week,
    end: add(week, { days: 6 }),
  });

  const daysOfWeekHeaders = daysOfWeek.map((date) =>
    format(date, `EEEE dd 'de' MMMM`, {
      locale: es,
      useAdditionalDayOfYearTokens: false,
    }),
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let fetchedProjects;
      let timeSheets;

      try {
        if (employee === false) {
          fetchedProjects = await getUserProjects(currentUser.uid);
          setProjects(fetchedProjects);
          timeSheets = await getUserTimeSheets(
            currentUser.uid,
            week,
            endOfWeek(week),
          );
        } else {
          fetchedProjects = await getUserProjects(employee);
          setProjects(fetchedProjects);
          timeSheets = await getUserTimeSheets(
            employee,
            week,
            endOfWeek(week, { weekStartsOn: 1 }),
          );
        }

        const timeSheetProjects = timeSheets.reduce((result, timeSheet) => {
          if (!result[timeSheet.project.id]) {
            return {
              ...result,
              [timeSheet.project.id]: {
                project: timeSheet.project,
                hours: timeSheet.hours,
                days: [timeSheet],
              },
            };
          }

          return {
            ...result,
            [timeSheet.project.id]: {
              project: result[timeSheet.project.id].project,
              hours: result[timeSheet.project.id].hours + timeSheet.hours,
              days: [...result[timeSheet.project.id].days, timeSheet],
            },
          };
        }, {});

        const usedProjects = Object.keys(timeSheetProjects);
        const parsedTimeSheets = Object.values(timeSheetProjects);

        const availableProjects = fetchedProjects.filter(
          (project) => !usedProjects.includes(project.id),
        );

        const billableProjectTimeSheets = availableProjects.reduce(
          (resultArray, project) => {
            if (project.billable) {
              const parsedProject = {
                id: project.id,
                name: project.name,
                client: project.client,
              };

              let publishingUserID = '';
              let publishingUserName = '';
              if (employee === false) {
                publishingUserID = currentUser.uid;
                publishingUserName = currentUser.displayName;
              } else {
                publishingUserID = employee;
                publishingUserName = empName;
              }

              return [
                ...resultArray,
                {
                  project: parsedProject,
                  days: daysOfWeek.map((day) => ({
                    date: day,
                    week,
                    month: getMonth(day),
                    year: getYear(day),
                    user: {
                      uid: publishingUserID,
                      name: publishingUserName,
                    },
                    project: parsedProject,
                    billable: project.billable,
                    hours: 0,
                  })),
                  hours: 0,
                },
              ];
            }

            return resultArray;
          },
          [],
        );
        setRows([...parsedTimeSheets, ...billableProjectTimeSheets]);
      } catch (error) {
        console.error(error);
        showSnackbar('Hubo un error, intentalo mas tarde.', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [week, employee]);

  const handleAddRow = (event, value, reason) => {
    switch (reason) {
      case 'selectOption': {
        const parsedProject = {
          id: value.id,
          name: value.name,
          client: value.client,
        };

        const targetUID = !employee ? currentUser.uid : employee;
        const targetName = !empName ? currentUser.displayName : empName;

        setRows((currentRows) => [
          ...currentRows,
          {
            project: parsedProject,
            days: daysOfWeek.map((day) => ({
              date: day,
              week,
              month: getMonth(day),
              year: getYear(day),
              user: { uid: targetUID, name: targetName },
              project: parsedProject,
              billable: value.billable,
              hours: 0,
            })),
            hours: 0,
          },
        ]);
        break;
      }
      case 'input':
        setAutocompleteValue(value);
        break;
      default:
        setAutocompleteValue('');
        break;
    }
  };

  const handleDeleteRow = async (selectedRow) => {
    if (selectedRow.days[0].id) {
      try {
        deleteRow(selectedRow);
        showSnackbar('Se han borrados las horas con exito!', 'success');
      } catch (error) {
        console.error(error);
        showSnackbar('Hubo un error, intentalo mas tarde.', 'error');
      } finally {
        setLoading(false);
        setRows((currentRows) =>
          currentRows.filter(
            (row) => row.project.id !== selectedRow.project.id,
          ),
        );
      }
    } else {
      setRows((currentRows) =>
        currentRows.filter((row) => row.project.id !== selectedRow.project.id),
      );
    }
  };

  const handleHourChange = (event) => {
    const { name, value } = event.target;

    if (Number.isNaN(event.target.value)) {
      return event.preventDefault();
    }

    const [pid, date] = name.split('_');

    setRows((currentRows) => {
      return currentRows.map((row) => {
        if (row.project.id === pid) {
          const days = row.days.map((day) => {
            if (date === format(day.date, 'yyyyMMdd')) {
              return { ...day, hours: Number(value) };
            }

            return day;
          });

          const hours = days.reduce((total, day) => total + day.hours, 0);

          return { ...row, hours, days };
        }

        return row;
      });
    });
  };

  const handleSubmit = async () => {
    const timeSheets = rows
      .flatMap((row) => row.days)
      .filter((row) => row.hours >= 0);
    try {
      const { uid, displayName: name } = currentUser;
      await setTimeSheets(timeSheets, { uid, name });
      showSnackbar('Horas registradas con éxito!', 'success');
    } catch (error) {
      console.error(error);
      showSnackbar('Hubo un error, intentalo mas tarde.', 'error');
    }
  };

  const projectsOptions = projects.filter(
    (project) => !rows.some((row) => row.project.id === project.id),
  );

  const totalHours = rows.reduce((total, row) => row.hours + total, 0);

  return (
    <>
      <Stack direction="row" spacing={1} marginBottom={0}>
        <Typography variant="h4">Horas Semana {formattedDate}</Typography>
        <DatePicker
          value={week}
          inputFormat="PPP"
          disableMaskedInput
          disableHighlightToday
          onChange={(newValue) =>
            isMonday(newValue)
              ? setWeek(newValue)
              : setWeek(previousMonday(newValue))
          }
          renderInput={({ inputRef, InputProps }) => (
            <Stack direction="row" alignItems="center">
              <TextField sx={{ width: 0, opacity: 0 }} ref={inputRef} />
              {InputProps.endAdornment}
            </Stack>
          )}
        />
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          onClick={() =>
            setWeek((currentValue) => previousMonday(currentValue))
          }
        >
          <NavigateBeforeIcon />
        </IconButton>
        <IconButton
          onClick={() => setWeek((currentValue) => nextMonday(currentValue))}
        >
          <NavigateNextIcon />
        </IconButton>
      </Stack>
      <Tooltip
        placement="right"
        title={
          totalHours > 40 ? 'Estas registrando más de 40 horas semanales' : ''
        }
      >
        <Typography
          marginBottom={2}
          sx={{
            color: (theme) =>
              totalHours > 40
                ? theme.palette.warning.main
                : theme.palette.text.primary,
            fontWeight: totalHours > 40 ? 'bold' : 'normal',
            display: 'inline-block',
            cursor: 'default',
          }}
        >
          Total: {totalHours}hrs
        </Typography>
      </Tooltip>

      <Paper sx={{ width: '100', padding: 2 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            errors,
            touched,
            isSubmitting,
            getFieldProps,
            setValues,
            values,
          }) => (
            <Form>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                {currentUser.role !== Roles.CONSULTANT && (
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth margin="normal">
                      <Autocomplete
                        options={users}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={errors.user && touched.user}
                            label="Consultor"
                          />
                        )}
                        {...getFieldProps('user')}
                        onChange={(event, value) => {
                          setValues({ ...values, user: value });
                          setEmployee(value.uid);
                          setEmpName(value.name);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth margin="normal">
                    <Autocomplete
                      options={projectsOptions}
                      getOptionLabel={(option) => option?.name || ''}
                      onChange={handleAddRow}
                      value={autocompleteValue}
                      inputValue={autocompleteValue}
                      onInputChange={handleAddRow}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Buscar y agregar proyecto"
                          InputProps={{ ...params.InputProps, type: 'search' }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth margin="normal">
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                      onClick={handleSubmit}
                      startIcon={<SaveIcon />}
                    >
                      Guardar Cambios
                    </LoadingButton>
                  </FormControl>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
      <Divider />
      <TableContainer component={Paper}>
        <Divider />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Proyecto</TableCell>
              {daysOfWeekHeaders.map((day) => (
                <TableCell
                  key={day.split(' ')[0]}
                  sx={{ textTransform: 'capitalize', fontSize: '0.75rem' }}
                  align="right"
                >
                  {day}
                </TableCell>
              ))}
              <TableCell align="right">Total</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.project.id}>
                <TableCell>{row.project.name}</TableCell>
                {row.days.map((day) => (
                  <TableCell
                    key={`${row.project.id}_${format(day.date, 'yyyyMMdd')}`}
                    align="right"
                  >
                    <Tooltip
                      title={
                        isWeekend(day.date) && day.hours > 0
                          ? 'Estas registrando horas en fin de semana'
                          : ''
                      }
                      placement="top"
                      color="warning"
                    >
                      <TextField
                        sx={{
                          maxWidth: '6rem',
                          '& .MuiInput-input': { textAlign: 'right' },
                        }}
                        name={`${row.project.id}_${format(
                          day.date,
                          'yyyyMMdd',
                        )}`}
                        value={day.hours}
                        onChange={handleHourChange}
                        type="number"
                        size="small"
                        variant="standard"
                        color={
                          isWeekend(day.date) && day.hours > 0
                            ? 'warning'
                            : 'primary'
                        }
                        focused={isWeekend(day.date) && day.hours > 0}
                      />
                    </Tooltip>
                  </TableCell>
                ))}
                <TableCell>{row.hours}hrs</TableCell>
                <TableCell>
                  <IconButton
                    variant="contained"
                    onClick={() => handleDeleteRow(row)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
