import './Tabs.css';

export default function Tabs({ items, value, onChange }) {
  return (
    <div className="tabs">
      {items.map((item, index) => (
        <button
          key={`${item}_tab`}
          type="button"
          className={`tab ${index === value ? 'active' : ''}`}
          aria-label={`${item} Tab`}
          onClick={() => onChange(index)}
        >
          {item}
        </button>
      ))}
    </div>
  );
}
