import { createContext, useContext, useMemo, useState } from 'react';
import Loader from '../components/Loader';

const LoadingContext = createContext();

export function useLoading() {
  return useContext(LoadingContext);
}

export function LoadingContextProvider({ children }) {
  const [loading, setLoading] = useState(false);

  const value = useMemo(() => ({ loading, setLoading }), [loading]);

  return (
    <LoadingContext.Provider value={value}>
      {loading ? <Loader /> : null}
      {children}
    </LoadingContext.Provider>
  );
}
