/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo-v2.png';
import './NavBar.css';
import NavBarItem from './NavBarItem';

export default function NavBar({ items = [], open, onClose }) {
  const location = useLocation();
  useEffect(() => onClose(), [location.pathname]);

  return (
    <>
      <nav className={`navbar${open ? ' open' : ''}`}>
        <header className="navbar__header">
          <img className="navbar__logo" src={logo} alt="KodeFree Logo" />
        </header>
        <hr className="separator" />
        <ul className="navbar__items">
          {items.map((item) => (
            <li className="navbar__item">
              <NavBarItem {...item} />
            </li>
          ))}
        </ul>
      </nav>
      <div className="navbar__overlay" onClick={onClose} />
    </>
  );
}
