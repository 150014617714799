import { createColumnHelper } from '@tanstack/table-core';
import { format, parse } from 'date-fns';
import es from 'date-fns/locale/es';
import { useMemo, useState } from 'react';
import Loader from '../../../components/Loader';
import Table from '../../../components/Table/Table';
import { useSnackbar } from '../../../context/SnackbarContext';
import { groupByProperty } from '../../../helpers/utils';
import { DateFilterLabel, DateFormat } from '../../../models/date-report.enum';
import { useTimeSheets } from '../../../services/timesheets';

export default function HoursReport() {
  const { showSnackbar } = useSnackbar();
  const [filter, setFilter] = useState('week');

  const columnHelper = createColumnHelper();
  const columns = useMemo(
    () => [
      columnHelper.accessor('user', {
        cell: (info) => info.getValue(),
        header: 'Consultor',
      }),
      columnHelper.accessor('client', {
        cell: (info) => info.getValue(),
        header: 'Cliente',
      }),
      columnHelper.accessor('project', {
        cell: (info) => info.getValue(),
        header: 'Proyecto',
      }),
      columnHelper.accessor(filter, {
        cell: (info) => {
          const formatedDate = info.getValue();
          return filter === 'week'
            ? new Date(formatedDate).toLocaleDateString('es-MX')
            : formatedDate;
        },
        header: DateFilterLabel[filter],
        sortingFn: (rowA, rowB, columnId) => {
          switch (columnId) {
            case 'week': {
              const dateA = new Date(rowA.getValue(columnId));
              const dateB = new Date(rowB.getValue(columnId));
              let dateSort = '';
              if (dateA < dateB) dateSort = -1;
              else if (dateA > dateB) dateSort = 1;
              else dateSort = 0;
              return dateSort;
            }

            case 'month': {
              const dateA = parse(
                rowA.getValue(columnId),
                'MM/yyyy',
                new Date(),
              );
              const dateB = parse(
                rowB.getValue(columnId),
                'MM/yyyy',
                new Date(),
              );
              let dateSort = '';
              if (dateA < dateB) dateSort = -1;
              else if (dateA > dateB) dateSort = 1;
              else dateSort = 0;
              return dateSort;
            }

            case 'year': {
              const dateA = rowA.getValue(columnId);
              const dateB = rowB.getValue(columnId);
              let dateSort = '';
              if (dateA < dateB) dateSort = -1;
              else if (dateA > dateB) dateSort = 1;
              else dateSort = 0;
              return dateSort;
            }

            default:
              console.log(columnId);
              break;
          }
        },
      }),
      columnHelper.accessor('hours', {
        cell: (info) => info.getValue(),
        header: 'Horas',
        enableGlobalFilter: false,
      }),
    ],
    [filter],
  );

  const exportProperties = useMemo(
    () => ({
      fileName: `Resumen_De_Horas_Por_${DateFilterLabel[filter]}_${format(
        new Date(),
        'yyyyMMdd',
      )}`,
      fields: [
        { label: 'Consultor', value: 'user' },
        { label: 'Cliente', value: 'client' },
        { label: 'Proyecto', value: 'project' },
        { label: DateFilterLabel[filter], value: filter },
        { label: 'Horas', value: 'hours' },
      ],
    }),
    [],
  );

  const [timeSheets, loading, error] = useTimeSheets();

  if (error) {
    console.error(error);
    showSnackbar('Hubo un error, intentalo mas tarde.', 'error');
  }

  const filteredData = timeSheets.map((timeSheet) => ({
    user: timeSheet.user.name,
    client: timeSheet.project.client,
    project: timeSheet.project.name,
    [filter]: format(
      filter === 'week' ? timeSheet.week : timeSheet.date,
      DateFormat[filter],
      { locale: es },
    ),
    hours: timeSheet.hours,
  }));

  const data = groupByProperty(
    filteredData,
    ['project', 'client', 'user', filter],
    'hours',
  );

  return (
    <>
      {loading ? <Loader /> : null}
      <h1 className="h1">Resumen de Horas</h1>
      <h3 className="h3">Periodo de tiempo:</h3>
      <fieldset className="fieldset">
        <label htmlFor="week" className="label">
          <input
            type="radio"
            className="radio"
            name="filter"
            checked={filter === 'week'}
            value="week"
            id="week"
            onChange={(e) => setFilter(e.target.value)}
          />
          Semana
        </label>
        <label htmlFor="month" className="label">
          <input
            type="radio"
            className="radio"
            name="filter"
            checked={filter === 'month'}
            value="month"
            id="month"
            onChange={(e) => setFilter(e.target.value)}
          />
          Mes
        </label>
        <label htmlFor="year" className="label">
          <input
            type="radio"
            className="radio"
            name="filter"
            checked={filter === 'year'}
            value="year"
            id="year"
            onChange={(e) => setFilter(e.target.value)}
          />
          Año
        </label>
      </fieldset>
      <br />
      <Table
        columns={columns}
        data={data}
        enableFilter
        enableSorting
        enableExport
        exportProperties={exportProperties}
      />
    </>
  );
}
