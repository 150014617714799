import { collection, getDocs, query, where } from 'firebase/firestore';
import { uniq } from 'lodash';
import { database } from '../firebase';
import { Roles } from '../models/user-roles.enum';

export const getApprovingAdmin = async () => {
  const q = query(collection(database, 'users'), where('approves', '==', true));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) {
    return undefined;
  }
  const admin = querySnapshot.docs[0].data();
  return { uid: admin.uid, name: admin.displayName };
};

export const getDependentUsers = async (currentUser) => {
  let q;
  switch (currentUser.role) {
    case Roles.MANAGER: {
      q = query(
        collection(database, 'users'),
        where('manager.uid', '==', currentUser.uid),
      );
      break;
    }

    case Roles.LEAD: {
      // TODO optimize data for this query, due to "in" operator limitation to 10 values per query
      const projectsQuery = query(
        collection(database, 'projects'),
        where('lead.uid', '==', currentUser.uid),
        where('isActive', '==', true),
      );
      const projectSnapshot = await getDocs(projectsQuery);
      const resources = projectSnapshot.docs.flatMap(
        (doc) => doc.data().resources,
      );

      q = query(
        collection(database, 'users'),
        where('uid', 'in', uniq(resources)),
      );
      break;
    }

    default: {
      q = query(collection(database, 'users'));
      break;
    }
  }

  const snapshot = await getDocs(q);

  return snapshot.docs.map((doc) => {
    const { uid, displayName } = doc.data();
    return { uid, name: displayName };
  });
};

export const getManager = async (currentUserId) => {
  const q = query(
    collection(database, 'users'),
    where('uid', '==', currentUserId),
  );

  const querySnapshot = await getDocs(q);
  return { ...querySnapshot.docs[0].data().manager };
};

export const getProjectLeads = async (currentUserId) => {
  const q = query(
    collection(database, 'projects'),
    where('resources', 'array-contains', currentUserId),
  );

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((doc) => doc.data().lead);
};

export const getUserRole = async (id) => {
  const q = query(collection(database, 'users'), where('uid', '==', id));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs[0].data().role;
};

export const getUsers = async () => {
  const q = query(collection(database, 'users'));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({
    uid: doc.data().uid,
    name: doc.data().displayName,
    role: doc.data().role,
  }));
};
