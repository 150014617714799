import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import { LoadingContextProvider } from './context/LoadingContext';
import './styles/global.css';

ReactDOM.render(
  <React.StrictMode>
    <LoadingContextProvider>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </LoadingContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
