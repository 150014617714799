import { onAuthStateChanged, signInWithRedirect } from 'firebase/auth';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { auth, provider } from '../firebase';
import { getLeadProjects } from '../services/projects';
import { getUserRole } from '../services/users';
import { useLoading } from './LoadingContext';
import { Roles } from '../models/user-roles.enum';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const { setLoading } = useLoading();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      if (!user) {
        return signInWithRedirect(auth, provider);
      }

      let role = await getUserRole(user.uid);
      const projects = await getLeadProjects(user.uid);
      if (role === 'consultant' && projects.length > 0) {
        role = Roles.LEAD;
      }
      const parseUser = {
        displayName: user.displayName,
        email: user.email,
        uid: user.uid,
        role,
        projects,
      };

      setCurrentUser(parseUser);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = useMemo(() => ({ currentUser }), [currentUser]);

  return (
    <AuthContext.Provider value={value}>
      {currentUser && children}
    </AuthContext.Provider>
  );
}
