import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Typography } from '@mui/material';

export default function DropFileInput({ error, files, setFiles }) {
  const onFileDrop = (e) => {
    const newFiles = e.target.files;

    if (newFiles?.length) {
      setFiles([...files, ...newFiles]);
    }
  };

  return (
    <Box
      sx={{
        borderWidth: '2px',
        borderStyle: 'dashed',
        borderColor: error ? 'error.main' : '#9E9E9E',
        minHeight: 160,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <CloudUploadIcon sx={{ fontSize: 96, opacity: 0.6 }} color="primary" />
      <Typography variant="body2">
        Arrastra y suelta tus archivos o da click aqui
      </Typography>
      <input
        style={{
          opacity: 0,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          cursor: 'pointer',
        }}
        multiple
        type="file"
        onChange={onFileDrop}
      />
    </Box>
  );
}
