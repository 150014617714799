import { format, startOfWeek, subWeeks } from 'date-fns';
import es from 'date-fns/locale/es';
import React, { useMemo, useState } from 'react';
import TabPanel from '../../components/TabPanel';
import Tabs from '../../components/Tabs/Tabs';
import Hours from './Hours';
import Projects from './Projects';
import './WeeklyMeeting.css';

export default function WeeklyMeeting() {
  const [tabIndex, setTabIndex] = useState(0);
  const week = subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1);
  const formattedWeek = format(week, 'PPP', { locale: es });
  const tabs = useMemo(() => ['Horas', 'Proyectos'], []);

  const handleTabs = (index) => {
    setTabIndex(index);
  };

  return (
    <>
      <h1 className="h1">Junta Semanal - {formattedWeek}</h1>
      <Tabs items={tabs} value={tabIndex} onChange={handleTabs} />
      <TabPanel className="weekly__tab-panel" value={tabIndex} index={0}>
        <Hours week={week} />
      </TabPanel>
      <TabPanel className="weekly__tab-panel" value={tabIndex} index={1}>
        <Projects week={week} />
      </TabPanel>
    </>
  );
}
