import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  FormHelperText,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { serverTimestamp } from 'firebase/firestore';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import DropFileInput from '../../components/DropFileInput';
import File from '../../components/File';
import { useAuth } from '../../context/AuthContext';
import { useSnackbar } from '../../context/SnackbarContext';
import { setProjectStatus } from '../../services/projects';

const validationSchema = yup.object({
  date: yup
    .date('Se requiere una fecha de cierre')
    .required('Se requiere una fecha de cierre'),
  justification: yup.string().required('Se requiere una justificación'),
});

export default function CloseProject() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pid } = useParams();
  const { currentUser } = useAuth();
  const { showSnackbar } = useSnackbar();
  const { projectName } = location.state;

  const [files, setFiles] = useState([]);

  const initialValues = {
    date: new Date(),
    justification: '',
  };

  const handleFileUpload = (file, url) => {
    const newFiles = [...files];
    newFiles[files.indexOf(file)].url = url;
    setFiles(newFiles);
  };

  const handleFileRemove = (file) => {
    const newFiles = [...files];
    newFiles.splice(files.indexOf(file), 1);
    setFiles(newFiles);
  };

  const handleSubmit = async (values) => {
    const parsedFiles = files.map((file) => ({
      name: file.name,
      size: file.size,
      url: file.url,
    }));

    const projectStatus = {
      color: 'grey',
      week: values.date,
      description: values.justification,
      files: parsedFiles,
      lastUpdatedBy: currentUser.uid,
      lastUpdatedDate: serverTimestamp(),
    };

    try {
      await setProjectStatus(pid, projectStatus);
      showSnackbar('El proyecto ha sido cerrado con exito', 'success');
      navigate('/proyectos');
    } catch (error) {
      console.error(error);
      showSnackbar('Hubo un error, intentalo mas tarde.', 'error');
    }
  };

  return (
    <>
      <Typography variant="h4">Cerrar Proyecto</Typography>
      <Paper sx={{ width: '100', padding: 2 }}>
        <Typography variant="h5" marginBottom={2}>
          {projectName}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            isSubmitting,
            isValid,
            getFieldProps,
            setFieldValue,
          }) => (
            <Form>
              <FormControl fullWidth margin="normal">
                <DatePicker
                  label="Fecha de cierre"
                  renderInput={(params) => <TextField {...params} />}
                  {...getFieldProps('date')}
                  onChange={(value) => setFieldValue('date', value)}
                />
                <FormHelperText error>{errors.date}</FormHelperText>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Justificación"
                  variant="outlined"
                  multiline
                  rows={5}
                  error={Boolean(errors.justification)}
                  {...getFieldProps('justification')}
                />
                <FormHelperText error>{errors.justification}</FormHelperText>
              </FormControl>
              <Typography variant="h6" gutterBottom>
                Documentación de cierre
              </Typography>
              <FormControl fullWidth margin="normal">
                <DropFileInput files={files} setFiles={setFiles} />
              </FormControl>
              {files.length > 0 && (
                <Stack
                  spacing={2}
                  sx={{
                    marginY: 1,
                    padding: 1,
                    maxHeight: 210,
                    overflowY: 'auto',
                    scrollbarWidth: 'thin',
                  }}
                >
                  {files.map((file, index) => (
                    <File
                      key={index}
                      onFileUpload={handleFileUpload}
                      onFileRemove={handleFileRemove}
                      file={file}
                      pid={pid}
                    />
                  ))}
                </Stack>
              )}
              <LoadingButton
                sx={{ marginTop: 1 }}
                loading={isSubmitting}
                type="submit"
                variant="contained"
                color="error"
                fullWidth
                disabled={!isValid}
                startIcon={<CloseIcon />}
              >
                Cerrar Proyecto
              </LoadingButton>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
}
