import CancelIcon from '@mui/icons-material/Cancel';
import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { startOfDay } from 'date-fns';
import { Form, Formik } from 'formik';
import { uniqWith } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useAuth } from '../../context/AuthContext';
import { useLoading } from '../../context/LoadingContext';
import { useSnackbar } from '../../context/SnackbarContext';
import { Roles } from '../../models/user-roles.enum';
import { getApprovingAdmin, getDependentUsers } from '../../services/users';
import { createVacation } from '../../services/vacations';

const validationSchema = yup.object({
  startDate: yup.date().required(),
  endDate: yup.date().required(),
  days: yup.number().required(),
  reason: yup.string().required(),
});

export default function NewVacations() {
  const { showSnackbar } = useSnackbar();
  const { currentUser } = useAuth();
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const initialValues = {
    user: { uid: currentUser.uid, name: currentUser.displayName },
    startDate: startOfDay(new Date()),
    endDate: startOfDay(new Date()),
    reason: '',
    days: '',
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      try {
        if (currentUser.role !== Roles.CONSULTANT) {
          const dependentUsers = await getDependentUsers(currentUser);
          setUsers(
            uniqWith(
              [
                { uid: currentUser.uid, name: currentUser.displayName },
                ...dependentUsers,
              ],
              (a, b) => a.uid === b.uid,
            ),
          );
        }
      } catch (error) {
        console.error(error);
        showSnackbar('Hubo un error, intentalo mas tarde.', 'error');
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  const handleSubmit = async (values) => {
    const admin = await getApprovingAdmin();
    const requiredApprovers = [admin];
    const requiredApprovals = requiredApprovers.map((approver) => approver.uid);

    const approvals = requiredApprovers.map((approver) => ({
      user: approver,
      status: 'Pendiente',
    }));

    const newVacations = {
      ...values,
      requiredApprovals,
      approvals,
      status: 'Pendiente',
    };

    try {
      await createVacation(newVacations);
      showSnackbar('Solicitud de vacaciones creada con éxito!', 'success');
      navigate('/vacaciones/gestionar');
    } catch (error) {
      console.error(error);
      showSnackbar('Hubo un error, intentalo mas tarde.', 'error');
    }
  };

  return (
    <>
      <Typography variant="h4">Solicitud de Vacaciones</Typography>
      <Paper sx={{ width: '100', padding: 2 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            errors,
            touched,
            isSubmitting,
            getFieldProps,
            setValues,
            values,
          }) => (
            <Form>
              <Grid container columnSpacing={3} alignItems="center">
                {currentUser.role !== Roles.CONSULTANT && (
                  <Grid item xs={12}>
                    <FormControl fullWidth margin="normal">
                      <Autocomplete
                        options={users}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={errors.user && touched.user}
                            label="Consultor"
                          />
                        )}
                        {...getFieldProps('user')}
                        onChange={(event, value) => {
                          setValues({ ...values, user: value });
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.uid === value.uid
                        }
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <DatePicker
                      label="Desde"
                      renderInput={(params) => <TextField {...params} />}
                      {...getFieldProps('startDate')}
                      onChange={(newValue) => {
                        setValues({ ...values, startDate: newValue });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <DatePicker
                      error={errors.endDate && touched.endDate}
                      minDate={new Date()}
                      label="Hasta"
                      renderInput={(params) => <TextField {...params} />}
                      {...getFieldProps('endDate')}
                      onChange={(newValue) => {
                        setValues({ ...values, endDate: newValue });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      type="number"
                      label="Días"
                      error={errors.days && touched.days}
                      InputProps={{ inputProps: { min: 1, max: 15 } }}
                      {...getFieldProps('days')}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="reason">Razón</InputLabel>
                    <Select
                      labelId="reason"
                      label="Razón"
                      error={errors.reason && touched.reason}
                      {...getFieldProps('reason')}
                    >
                      <MenuItem value="vacation"> Vacaciones </MenuItem>
                      <MenuItem value="kodefree"> Dias KodeFree </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <FormControl fullWidth margin="normal">
                <Stack direction="row" spacing={3}>
                  <Button
                    onClick={() => navigate('/vacaciones/gestionar')}
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    fullWidth
                  >
                    Cancelar
                  </Button>

                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<SendIcon />}
                    loading={isSubmitting}
                    fullWidth
                  >
                    Enviar Solicitud
                  </LoadingButton>
                </Stack>
              </FormControl>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
}
