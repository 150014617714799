import { Table } from '@mui/material';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { get, isPlainObject } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export default function CustomTable({ columns, rows }) {
  const getCellContent = (key, value, row) => {
    if (isPlainObject(value) && !React.isValidElement(value)) {
      const columnDef = columns.find((column) => column.field === key);
      return get(row, columnDef.lookUp, '');
    }

    return value || '';
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell align="center" key={`${column.field}_column`}>
                {column.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              {Object.entries(row)
                .filter(([rowKey]) => rowKey !== 'id')
                .map(([rowKey, rowValue]) => (
                  <TableCell key={`${row.id}_${rowKey}`} align="center">
                    {getCellContent(rowKey, rowValue, row)}
                  </TableCell>
                ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

CustomTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string.isRequired,
      lookUp: PropTypes.string,
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.object,
        PropTypes.number,
      ]),
    ),
  ).isRequired,
};
