const projectFilters = {
  color: {
    label: 'Estatus',
    field: 'currentStatus.color',
    values: [
      { label: 'Verde', value: 'green' },
      { label: 'Amarillo', value: 'yellow' },
      { label: 'Rojo', value: 'red' },
      { label: 'Azul', value: 'blue' },
    ],
  },
  type: {
    label: 'Tipo',
    values: ['Interno', 'Cerrado', 'Tiempos y Materiales'],
  },
};

export default projectFilters;
