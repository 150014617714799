import { blue, green, grey, red, yellow } from '@mui/material/colors';

export const statusColor = {
  green: green[700],
  yellow: yellow[700],
  red: red[700],
  blue: blue[700],
  grey: grey[700],
  white: 'white',
};

export const projectStatusOptions = [
  { color: statusColor.green, label: 'Verde', value: 'green' },
  { color: statusColor.yellow, label: 'Amarillo', value: 'yellow' },
  { color: statusColor.red, label: 'Rojo', value: 'red' },
  { color: statusColor.blue, label: 'Azul', value: 'blue' },
];
