import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { Stack } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';

import { Form, Formik } from 'formik';

import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { useAuth } from '../../context/AuthContext';
import { useLoading } from '../../context/LoadingContext';
import { useSnackbar } from '../../context/SnackbarContext';
import { projectTechnologies } from '../../helpers/project-technologies';
import { projectTypes } from '../../helpers/project-types';
import { Roles } from '../../models/user-roles.enum';
import { setProject } from '../../services/projects';
import { getUsers } from '../../services/users';

const validationSchema = yup.object({
  name: yup.string().required(),
  client: yup.string().required(),
  lead: yup.object({
    name: yup.string().required(),
  }),
  type: yup.string().required(),
  country: yup.string().required(),
  hours: yup.number(),
  resources: yup.array().min(1).required(),
});

export default function ProjectDetails() {
  const [users, setUsers] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const { currentUser } = useAuth();
  const { showSnackbar } = useSnackbar();
  const { setLoading } = useLoading();

  const navigate = useNavigate();
  const location = useLocation();

  const { pid } = useParams();
  const [initialValues, setInitialValues] = useState({
    name: '',
    client: '',
    lead: { name: '' },
    type: '',
    billable: true,
    country: '',
    startDate: new Date(),
    endDate: new Date(),
    hours: '',
    technologies: [],
    resources: [],
  });

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const users = await getUsers();
        setUsers(users);
        if (pid?.length > 0) {
          setInitialValues({
            ...location.state,
            client: location.state.client,
            lead: location.state.lead,
            startDate: location.state.startDate,
            endDate: location.state.endDate || null,
            resources: users.filter((user) =>
              location.state.resources.includes(user.uid),
            ),
          });

          const disabled = pid
            ? currentUser.role !== Roles.ADMIN &&
              !currentUser.projects.includes(pid)
            : false;

          setIsDisabled(disabled);

          if (disabled) {
            showSnackbar(
              'Parece que no cuentas con los permisos suficientes para editar el proyecto!',
              'warning',
            );
          }
        }
      } catch (error) {
        console.error(error);
        showSnackbar('Hubo un error, intentalo mas tarde.', 'error');
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  const handleSubmit = async (values) => {
    if (pid) {
      const editedProject = {
        ...values,
        client: values.client,
        lastUpdateDate: new Date(),
        lastUpdatedBy: currentUser.uid,
        resources: values.resources.map((user) => user.uid),
      };
      delete editedProject.id;
      await setProject(editedProject, pid);
      showSnackbar('Proyecto actualizado con éxito!', 'success');
      navigate('/proyectos');
      return;
    }

    const newProject = {
      ...values,
      resources: values.resources.map((resource) => resource.uid),
      lead: { uid: values.lead.uid, name: values.lead.name },
      lastUpdateDate: new Date(),
      lastUpdatedBy: currentUser.uid,
      isActive: true,
      hasStatus: false,
    };

    try {
      await setProject(newProject, null);
      showSnackbar('Proyecto agregado con éxito!', 'success');
      navigate('/proyectos');
    } catch (error) {
      console.error(error);
      showSnackbar('Hubo un error, intentalo mas tarde.', 'error');
    }
  };

  return (
    <>
      <Typography variant="h4">
        {location.state ? 'Editar Proyecto' : 'Nuevo Proyecto'}
      </Typography>
      <Paper sx={{ width: '100', padding: 2 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            errors,
            touched,
            isSubmitting,
            getFieldProps,
            setValues,
            values,
          }) => (
            <Form>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Nombre"
                  variant="outlined"
                  disabled={isDisabled}
                  error={errors.name && touched.name}
                  {...getFieldProps('name')}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Cliente"
                  variant="outlined"
                  disabled={isDisabled}
                  error={errors.client && touched.client}
                  {...getFieldProps('client')}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  autoHighlight
                  freeSolo
                  options={users}
                  getOptionLabel={(option) => option.name}
                  disabled={isDisabled}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.lead && touched.lead}
                      label="Lider"
                    />
                  )}
                  {...getFieldProps('lead')}
                  onChange={(event, value) => {
                    setValues({ ...values, lead: value });
                  }}
                />
              </FormControl>
              <Grid container columnSpacing={3} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="project-type">Tipo de Proyecto</InputLabel>
                    <Select
                      labelId="project-type"
                      label="Tipo de proyecto"
                      disabled={isDisabled}
                      error={errors.type && touched.type}
                      {...getFieldProps('type')}
                    >
                      {projectTypes.map((type, i) => (
                        <MenuItem key={i} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="country">País</InputLabel>
                    <Select
                      labelId="country"
                      label="País"
                      disabled={isDisabled}
                      error={errors.country && touched.country}
                      {...getFieldProps('country')}
                    >
                      <MenuItem value="México"> México </MenuItem>
                      <MenuItem value="Estados Unidos">Estados Unidos</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth margin="normal">
                    <FormControlLabel
                      label="Facturable"
                      disabled={isDisabled}
                      control={<Checkbox defaultChecked />}
                      {...getFieldProps('billable')}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <DatePicker
                      label="Fecha Inicio"
                      disabled={isDisabled}
                      renderInput={(params) => <TextField {...params} />}
                      {...getFieldProps('startDate')}
                      onChange={(newValue) => {
                        setValues({ ...values, startDate: newValue });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <DatePicker
                      label="Fecha Fin"
                      disabled={isDisabled}
                      renderInput={(params) => <TextField {...params} />}
                      {...getFieldProps('endDate')}
                      onChange={(newValue) => {
                        setValues({ ...values, endDate: newValue });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      label="Horas"
                      variant="outlined"
                      disabled={isDisabled}
                      {...getFieldProps('hours')}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <Autocomplete
                      options={projectTechnologies}
                      disabled={isDisabled}
                      autoHighlight
                      freeSolo
                      multiple
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Seleccionar tecnologías"
                        />
                      )}
                      {...getFieldProps('technologies')}
                      onChange={(event, value) => {
                        setValues({ ...values, technologies: value });
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Typography variant="h6">Recursos</Typography>
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  options={users}
                  autoHighlight
                  freeSolo
                  disabled={isDisabled}
                  getOptionLabel={(option) => option.name}
                  multiple
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.resources && touched.resources}
                      label="Buscar un recurso"
                    />
                  )}
                  {...getFieldProps('resources')}
                  onChange={(event, value) => {
                    setValues({ ...values, resources: value });
                  }}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <Stack direction="row" spacing={3}>
                  <Button
                    onClick={() => navigate('/proyectos')}
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    fullWidth
                  >
                    Cancelar
                  </Button>

                  <LoadingButton
                    type="submit"
                    variant="contained"
                    disabled={isDisabled}
                    loading={isSubmitting}
                    startIcon={<SaveIcon />}
                    fullWidth
                  >
                    Guardar Cambios
                  </LoadingButton>
                </Stack>
              </FormControl>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
}
