export const DateFormat = {
  week: 'MM/dd/yyyy',
  month: 'MM/yyyy',
  year: 'yyyy',
};

export const DateFilterLabel = {
  week: 'Semana',
  month: 'Mes',
  year: 'Año',
};
