export const projectTechnologies = [
  'Azure',
  'Appian',
  'GCP',
  'MySQL',
  'IPC',
  'MicroStrategy',
  'PowerBI',
  'Python',
  'React',
  'FireBase',
];
