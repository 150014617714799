export const groupByProperty = (array, properties, metric) => {
  const data = array.reduce((result, currentValue) => {
    const key = properties.map((prop) => currentValue[prop]).join(';');
    return {
      ...result,
      [key]: (result[key] || 0) + parseFloat(currentValue[metric]),
    };
  }, {});

  return Object.entries(data).map(([key, value]) => {
    const splitKeys = key.split(';');
    return {
      ...splitKeys.reduce((result, currentValue, index) => {
        return { ...result, [properties[index]]: currentValue };
      }, {}),
      [metric]: value,
    };
  });
};

export const downloadCSV = (data, fileName) => {
  const blob = new Blob([data], { type: 'text/csv' });
  const clickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  const anchor = document.createElement('a');
  anchor.download = fileName;
  anchor.href = window.URL.createObjectURL(blob);
  anchor.dispatchEvent(clickEvent);
  anchor.remove();
};
