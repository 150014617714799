import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import { serverTimestamp } from 'firebase/firestore';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { startOfWeek, subWeeks, isMonday, previousMonday } from 'date-fns';
import DropFileInput from '../../components/DropFileInput';
import File from '../../components/File';
import { useAuth } from '../../context/AuthContext';
import { useLoading } from '../../context/LoadingContext';
import { useSnackbar } from '../../context/SnackbarContext';
import { projectStatusOptions } from '../../helpers/project-status';
import { getProjectStatus, setProjectStatus } from '../../services/projects';

const validationSchema = yup.object({
  week: yup.date().required(),
  color: yup.object().required('Por favor selecciona un estatus.'),
  description: yup.string().when('color.value', {
    is: (value) => value !== 'green',
    then: yup
      .string()
      .required('Se requiere una descripción para este estatus'),
    otherwise: yup.string().notRequired(),
  }),
});

export default function ProjectStatus() {
  const week = subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1);
  const navigate = useNavigate();
  const location = useLocation();

  const { pid } = useParams();
  const { currentUser } = useAuth();
  const { showSnackbar } = useSnackbar();
  const { setLoading } = useLoading();

  const { projectName, isReadOnly } = location.state;

  const [initialValues, setInitialValues] = useState({
    week,
    color: '',
    description: '',
  });
  const [files, setFiles] = useState([]);
  const [docId, setDocId] = useState();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const projectStatus = await getProjectStatus(pid, week);

        if (!projectStatus) {
          return;
        }

        const color = projectStatusOptions.find(
          (s) => s.value === projectStatus.color,
        );

        const values = {
          week: projectStatus.week,
          color,
          description: projectStatus.description || '',
        };

        setInitialValues(values);
        setDocId(projectStatus.docId);
        setFiles(projectStatus.files || []);
      } catch (error) {
        console.error(error);
        showSnackbar('Hubo un error, intentalo mas tarde.', 'error');
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  const handleSubmit = async (values) => {
    const parsedFiles = files.map((file) => ({
      name: file.name,
      size: file.size,
      url: file.url,
    }));

    const projectStatus = {
      color: values.color.value,
      week: values.week,
      description: values.description,
      files: parsedFiles,
      lastUpdatedBy: currentUser.uid,
      lastUpdatedDate: serverTimestamp(),
    };

    try {
      await setProjectStatus(pid, projectStatus, docId);
      showSnackbar(
        `Estatus de proyecto ha sido ${
          docId ? 'modificado' : 'agregado'
        } con exito!`,
        'success',
      );
      navigate('/proyectos');
    } catch (error) {
      console.error(error);
      showSnackbar('Hubo un error, intentalo mas tarde.', 'error');
    }
  };

  const handleFileUpload = (file, url) => {
    const newFiles = [...files];
    newFiles[files.indexOf(file)].url = url;
    setFiles(newFiles);
  };

  const handleFileRemove = (file) => {
    const newFiles = [...files];
    newFiles.splice(files.indexOf(file), 1);
    setFiles(newFiles);
  };

  return (
    <>
      <Typography variant="h4">Estatus de proyecto</Typography>
      <Paper sx={{ width: '100', padding: 2 }}>
        <Typography variant="h5" marginBottom={2}>
          {projectName}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            errors,
            isSubmitting,
            isValid,
            getFieldProps,
            setFieldValue,
          }) => (
            <Form>
              <FormControl fullWidth margin="normal">
                <DatePicker
                  label="Semana"
                  renderInput={(params) => <TextField {...params} />}
                  {...getFieldProps('week')}
                  onChange={(newValue) =>
                    isMonday(newValue)
                      ? setFieldValue('week', newValue)
                      : setFieldValue(previousMonday('week', newValue))
                  }
                />
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                error={Boolean(errors.color)}
              >
                <InputLabel id="status-select-label">Estatus</InputLabel>
                <Select
                  labelId="status-select-label"
                  label="Estatus"
                  disabled={isReadOnly}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex' }}>
                      <Box
                        sx={{
                          backgroundColor: selected.color,
                          width: 24,
                          height: 24,
                          borderRadius: 5,
                          marginRight: 2,
                          display: 'inline-block',
                        }}
                      />
                      {selected.label}
                    </Box>
                  )}
                  {...getFieldProps('color')}
                >
                  {projectStatusOptions.map((status) => (
                    <MenuItem key={status.value} value={status}>
                      {status.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>{errors.color}</FormHelperText>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Descripción"
                  disabled={isReadOnly}
                  variant="outlined"
                  multiline
                  rows={5}
                  error={Boolean(errors.description)}
                  {...getFieldProps('description')}
                />
                <FormHelperText error>{errors.description}</FormHelperText>
              </FormControl>
              {!isReadOnly && !files.length && (
                <Typography variant="h6" gutterBottom>
                  {isReadOnly ? 'Archivos' : 'Adjuntar Archivos'}
                </Typography>
              )}
              {!isReadOnly && (
                <FormControl fullWidth margin="normal">
                  <DropFileInput files={files} setFiles={setFiles} />
                </FormControl>
              )}
              {files.length > 0 && (
                <Stack
                  spacing={2}
                  sx={{
                    marginY: 1,
                    padding: 1,
                    maxHeight: 210,
                    overflowY: 'auto',
                    scrollbarWidth: 'thin',
                  }}
                >
                  {files.map((file, index) => (
                    <File
                      key={index}
                      onFileUpload={handleFileUpload}
                      onFileRemove={handleFileRemove}
                      file={file}
                      pid={pid}
                      hideActionButton={isReadOnly}
                    />
                  ))}
                </Stack>
              )}

              {!isReadOnly && (
                <FormControl fullWidth margin="normal">
                  <LoadingButton
                    loading={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!isValid}
                    startIcon={<SaveIcon />}
                  >
                    Guardar Cambios
                  </LoadingButton>
                </FormControl>
              )}
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
}
