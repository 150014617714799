export const vacationsColors = [
  {
    color: '#c2ebd6',
    textColor: '#226543',
  },
  {
    color: '#ffd3d2',
    textColor: '#7e3533',
  },
  {
    color: '#ffeebc',
    textColor: '#9c8543',
  },
  {
    color: '#c3ccdf',
    textColor: '#475571',
  },
  {
    color: '#c8e4ff',
    textColor: '#2c5a85',
  },
];
