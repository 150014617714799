import { Box, Container, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import { useMemo, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Icon from './components/Icon';
import NavBar from './components/NavBar';
import Toolbar from './components/Toolbar';
import { useAuth } from './context/AuthContext';
import { SnackbarContextProvider } from './context/SnackbarContext';
import { navbarOptions } from './helpers/navbar-options';
import Home from './pages/Home';
import HoursRecord from './pages/Hours/Record/Record';
import HoursReport from './pages/Hours/Report/Report';
import CloseProject from './pages/Projects/CloseProject';
import ProjectDetails from './pages/Projects/ProjectDetails';
import Projects from './pages/Projects/Projects';
import ProjectsList from './pages/Projects/ProjectsList';
import ProjectStatus from './pages/Projects/ProjectStatus';
import AuthorizeVacations from './pages/Vacations/AuthorizeVacations';
import EditVacations from './pages/Vacations/EditVacations';
import MyVacations from './pages/Vacations/MyVacations';
import NewVacations from './pages/Vacations/NewVacations';
import Vacations from './pages/Vacations/Vacations';
import VacationsList from './pages/Vacations/VacationsList';
import WeeklyMeeting from './pages/WeeklyMeeting';
import theme from './theme';

export default function App() {
  const { currentUser } = useAuth();
  const [mobileOpen, setMobileOpen] = useState(true);

  const menuItems = useMemo(
    () =>
      navbarOptions.reduce((itemsArray, currentItem) => {
        if (
          currentItem.roles &&
          !currentItem.roles.includes(currentUser.role)
        ) {
          return itemsArray;
        }

        if (currentItem.children) {
          const children = currentItem.children.filter((child) => {
            if (child.roles && !child.roles.includes(currentUser.role)) {
              return false;
            }

            return true;
          });

          return [...itemsArray, { ...currentItem, children }];
        }

        return [...itemsArray, currentItem];
      }, []),
    [],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            backgroundColor: '#f7f9fc',
            width: '100%',
            height: '100vh',
            paddingLeft: { md: 30 },
          }}
        >
          <CssBaseline />
          <BrowserRouter>
            <Toolbar>
              <button
                type="button"
                className="button button--icon ripple mobile-only"
                onClick={() => setMobileOpen(true)}
              >
                <Icon>menu</Icon>
              </button>
            </Toolbar>
            <NavBar
              items={menuItems}
              open={mobileOpen}
              onClose={() => setMobileOpen(false)}
            />
            <Box
              component="main"
              sx={{
                width: '100%',
                overflowY: 'auto',
                paddingBottom: '2rem',
              }}
            >
              <SnackbarContextProvider>
                <Container maxWidth="lg">
                  <Routes>
                    {/*
                    Will temporarily change / path from Home to ProjectList while actual landing page is in development
                    */}
                    <Route path="/" element={<Home />} />
                    <Route path="junta_semanal" element={<WeeklyMeeting />} />
                    <Route path="proyectos" element={<Projects />}>
                      <Route path="" element={<ProjectsList />} />
                      <Route path="nuevo" element={<ProjectDetails />} />
                      <Route path=":pid" element={<ProjectDetails />} />
                      <Route path=":pid/estatus" element={<ProjectStatus />} />
                      <Route path=":pid/cerrar" element={<CloseProject />} />
                    </Route>
                    <Route path="horas">
                      <Route path="registrar" element={<HoursRecord />} />
                      <Route path="resumen" element={<HoursReport />} />
                    </Route>
                    <Route path="vacaciones" element={<Vacations />}>
                      <Route path="gestionar" element={<MyVacations />} />
                      <Route path="calendario" element={<VacationsList />} />
                      <Route path="aprobar" element={<AuthorizeVacations />} />
                      <Route path="nuevas" element={<NewVacations />} />
                      <Route path=":vid" element={<EditVacations />} />
                    </Route>
                  </Routes>
                </Container>
              </SnackbarContextProvider>
            </Box>
          </BrowserRouter>
        </Box>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
