import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { isEqual, unionWith } from 'lodash';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { database } from '../firebase';

const projectConverter = {
  toFirestore: (project) => {
    return {
      ...project,
      currentStatus: project.currentStatus
        ? {
            ...project.currentStatus,
            week: Timestamp.fromDate(project.currentStatus.week),
          }
        : undefined,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    const { currentStatus, endDate, startDate, lastUpdateDate } = data;

    return {
      ...data,
      currentStatus: currentStatus
        ? { ...currentStatus, week: currentStatus?.week.toDate() }
        : undefined,
      endDate: endDate?.toDate(),
      startDate: startDate.toDate(),
      lastUpdateDate: lastUpdateDate.toDate(),
      id: snapshot.id,
    };
  },
};

const statusConverter = {
  toFirestore: (status) => {
    return {
      ...status,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    const { lastUpdateDate } = data;

    return {
      ...data,
      lastUpdateDate: lastUpdateDate.toDate(),
    };
  },
};

const projectsCollection = collection(database, 'projects');

export const useActiveProjects = () => {
  const q = query(projectsCollection, where('isActive', '==', true));

  return useCollectionData(q.withConverter(projectConverter), {
    initialValue: [],
  });
};

export const getProjects = async (field, operator, value) => {
  const q = query(
    collection(database, 'projects'),
    where(field, operator, value),
    where('isActive', '==', true),
  );

  const snapshot = await getDocs(q);

  return snapshot.docs.map((doc) => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      startDate: data.startDate.toDate(),
      endDate: data.endDate?.toDate(),
    };
  });
};

export const getActiveProjects = async () => {
  const q = query(
    collection(database, 'projects'),
    where('isActive', '==', true),
  );
  const snapshot = await getDocs(q);

  return snapshot.docs.map((doc) => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      startDate: data.startDate.toDate(),
      endDate: data.endDate?.toDate(),
    };
  });
};

export const getLeadProjects = async (uid) => {
  const q = query(
    collection(database, 'projects'),
    where('lead.uid', '==', uid),
    where('isActive', '==', true),
  );

  const snapshot = await getDocs(q);

  return snapshot.docs.map((doc) => doc.id);
};

export const getUserProjects = async (uid) => {
  const projectsRef = collection(database, 'projects');
  const resourceQuery = query(
    projectsRef,
    where('resources', 'array-contains', uid),
    where('isActive', '==', true),
  );
  const leadQuery = query(
    projectsRef,
    where('lead.uid', '==', uid),
    where('isActive', '==', true),
  );

  const [resourceSnapshot, leadSnapshot] = await Promise.all([
    getDocs(resourceQuery),
    getDocs(leadQuery),
  ]);

  return unionWith(resourceSnapshot.docs, leadSnapshot.docs, isEqual).map(
    (doc) => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
        startDate: data.startDate.toDate(),
        endDate: data.endDate?.toDate(),
      };
    },
  );
};

export const getProjectStatus = async (id, week) => {
  const statusRef = collection(database, `projects/${id}/status`);
  const statusQuery = query(
    statusRef,
    where('week', '==', Timestamp.fromDate(week)),
  );

  const snapshot = await getDocs(statusQuery);

  if (snapshot.empty) {
    return null;
  }

  return {
    ...snapshot.docs[0].data(),
    docId: snapshot.docs[0].id,
    week: snapshot.docs[0].data().week.toDate(),
  };
};

export const setProjectStatus = async (projectId, status, docId) => {
  const statusRef = docId
    ? doc(database, 'projects', projectId, 'status', docId)
    : doc(collection(database, 'projects', projectId, 'status'));
  await setDoc(statusRef.withConverter(statusConverter), status);

  const projectUpdate = {
    currentStatus: {
      week: status.week,
      color: status.color,
      description: status.description,
    },
  };

  if (status.color === 'grey') {
    projectUpdate.isActive = false;
  }

  const projectRef = doc(database, 'projects', projectId).withConverter(
    projectConverter,
  );
  await updateDoc(projectRef, projectUpdate);
};

export const setProject = async (project, projectId) => {
  if (projectId) {
    await updateDoc(doc(database, 'projects', projectId), project);
    return;
  }

  await addDoc(collection(database, 'projects'), project);
};
