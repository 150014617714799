import { Avatar, Link, Paper, Stack, Typography } from '@mui/material';
import { format, startOfWeek } from 'date-fns';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { useEffect, useState } from 'react';
import { storage } from '../firebase';
import { STATUS } from '../helpers/file-upload-status';
import FileUploadButton from './FileUploadButton';

const formatFileSize = (size) => {
  if (size < 1024) {
    return `${size} B`;
  }
  if (size < 1024 ** 2) {
    return `${(size / 1024).toFixed(2)} KB`;
  }
  if (size < 1024 ** 3) {
    return `${(size / 1024 ** 2).toFixed(2)} MB`;
  }
  if (size < 1024 ** 4) {
    return `${(size / 1024 ** 3).toFixed(2)} GB`;
  }
};

export default function File({
  file,
  pid,
  onFileUpload,
  onFileRemove,
  hideActionButton,
}) {
  const extension = file.name.split('.').pop();
  const [status, setStatus] = useState(STATUS.LOADING);
  const [progress, setProgress] = useState(0);
  const [storageRef, setStorageRef] = useState();
  const [uploadTask, setUploadTask] = useState();

  useEffect(() => {
    const initialize = async () => {
      const week = format(
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        'yyyyMMdd',
      );

      const sRef = ref(storage, `projects/${pid}/status/${week}/${file.name}`);
      setStorageRef(sRef);

      if (file.url) {
        setStatus(STATUS.DONE);
        return;
      }

      try {
        const uTask = uploadBytesResumable(sRef, file);
        uTask.on(
          'state_changed',
          (snapshot) => {
            const prog = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            );
            setProgress(prog);
          },
          (err) => console.log(err),
          async () => {
            setStatus(STATUS.DONE);
            const url = await getDownloadURL(sRef);
            onFileUpload(file, url);
          },
        );

        setUploadTask(uTask);
      } catch (err) {
        console.error(err);
      }
    };

    initialize();
  }, []);

  const handleActionClick = async () => {
    switch (status) {
      case STATUS.LOADING:
        uploadTask.pause();
        setStatus(STATUS.PAUSED);
        break;
      case STATUS.PAUSED:
        uploadTask.resume();
        setStatus(STATUS.LOADING);
        break;
      case STATUS.DONE:
        await deleteObject(storageRef);
        onFileRemove();
        break;
      default:
        break;
    }
  };

  return (
    <Paper
      sx={{
        paddingY: 1,
        paddingX: 2,
        backgroundColor: '#f0edff',
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Avatar
            variant="rounded"
            sx={{
              backgroundColor: 'primary.main',
              fontSize: '0.8rem',
              paddingX: 3,
            }}
          >
            {extension.toUpperCase()}
          </Avatar>
          <Stack>
            <Link
              variant="body2"
              sx={{ cursor: 'pointer' }}
              href={file.url}
              target="_blank"
            >
              {file.name}
            </Link>
            <Typography variant="caption">
              {formatFileSize(file.size)}
            </Typography>
          </Stack>
        </Stack>
        {!hideActionButton && (
          <FileUploadButton
            onClick={handleActionClick}
            progress={progress}
            status={status}
          />
        )}
      </Stack>
    </Paper>
  );
}
