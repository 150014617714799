import { startOfDay } from 'date-fns';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { database } from '../firebase';
import { Roles } from '../models/user-roles.enum';

export const createVacation = async (vacation) => {
  await addDoc(collection(database, 'vacations'), vacation);
};

export const deleteVacation = async (docId) => {
  await deleteDoc(doc(database, 'vacations', docId));
};

export const getVacations = async (field, operator, value) => {
  const q = query(
    collection(database, 'vacations'),
    where(field, operator, value),
  );
  const snapshot = await getDocs(q);
  return snapshot.docs.map((doc) => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      startDate: data.startDate.toDate(),
      endDate: data.endDate.toDate(),
    };
  });
};

export const getUserVacations = async (currentUser) => {
  const today = startOfDay(new Date());
  const queries = [];

  const userVacationsQuery = query(
    collection(database, 'vacations'),
    where('user.uid', '==', currentUser.uid),
    where('endDate', '>=', Timestamp.fromDate(today)),
  );

  queries.push(getDocs(userVacationsQuery));

  if (currentUser.role !== Roles.CONSULTANT) {
    const authorizeVacationsQuery = query(
      collection(database, 'vacations'),
      where('requiredApprovals', 'array-contains', currentUser.uid),
      where('endDate', '>=', Timestamp.fromDate(today)),
    );

    queries.push(getDocs(authorizeVacationsQuery));
  }

  const snapshots = await Promise.all(queries);

  return snapshots.flatMap((snapshot) =>
    snapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
        startDate: data.startDate.toDate(),
        endDate: data.endDate.toDate(),
      };
    }),
  );
};

export const onPendingVacationsSnapshot = (uid, cb) => {
  const q = query(
    collection(database, 'vacations'),
    where('requiredApprovals', 'array-contains', uid),
    where('status', '==', 'Pendiente'),
  );

  return onSnapshot(q, cb);
};

export const onVacationsSnapshot = (currentUser, cb) => {
  const today = new Date();
  let q;

  if (currentUser.role === Roles.CONSULTANT) {
    q = query(
      collection(database, 'vacations'),
      where('user.uid', '==', currentUser.uid),
      where('endDate', '>=', Timestamp.fromDate(today)),
    );
  } else {
    q = query(
      collection(database, 'vacations'),
      where('user.uid', '==', currentUser.uid),
      where('requiredApprovals', 'array-contains', currentUser.uid),
      where('endDate', '>=', Timestamp.fromDate(today)),
    );
  }

  return onSnapshot(q, cb);
};

export const setVacation = async (vacation, docId) => {
  await setDoc(doc(database, 'vacations', docId), vacation);
};

export const updateVacation = async (id, payload) => {
  const vacationRef = doc(database, 'vacations', id);
  await updateDoc(vacationRef, payload);
};
