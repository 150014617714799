import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  Timestamp,
  where,
  writeBatch,
} from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { database } from '../firebase';

const timeSheetConverter = {
  toFirestore: (timeSheet) => {
    return { ...timeSheet };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    const { date, week, lastUpdatedDate } = data;

    return {
      ...data,
      date: date.toDate(),
      week: week.toDate(),
      lastUpdatedDate: lastUpdatedDate.toDate(),
      id: snapshot.id,
    };
  },
};

const timeSheetsCollection = collection(database, 'timeSheets');

export const useTimeSheetsByWeek = (week) => {
  const q = query(
    timeSheetsCollection,
    where('week', '==', Timestamp.fromDate(week)),
  );

  return useCollectionData(q.withConverter(timeSheetConverter), {
    initialValue: [],
  });
};

export const useTimeSheets = () => {
  return useCollectionData(
    timeSheetsCollection.withConverter(timeSheetConverter),
    { initialValue: [] },
  );
};

export const getTimeSheets = async (week) => {
  let q;

  if (week) {
    q = query(
      collection(database, 'timeSheets'),
      where('week', '==', Timestamp.fromDate(week)),
    );
  } else {
    q = query(collection(database, 'timeSheets'), orderBy('project.name'));
  }

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((doc) => {
    const timeSheet = { id: doc.id, ...doc.data() };
    const { date, week, lastUpdatedDate } = timeSheet;

    return {
      ...timeSheet,
      date: date.toDate(),
      week: week.toDate(),
      lastUpdatedDate: lastUpdatedDate.toDate(),
    };
  });
};

export const getUserTimeSheets = async (uid, startDate, endDate) => {
  const q = query(
    collection(database, 'timeSheets'),
    where('user.uid', '==', uid),
    where('date', '>=', Timestamp.fromDate(startDate)),
    where('date', '<', Timestamp.fromDate(endDate)),
  );

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((doc) => {
    const timeSheet = { id: doc.id, ...doc.data() };
    const { date, week } = timeSheet;

    return {
      ...timeSheet,
      date: date.toDate(),
      week: week.toDate(),
    };
  });
};

export const setTimeSheets = async (timeSheets, lastUpdatedBy) => {
  const batch = writeBatch(database);

  timeSheets.forEach((timeSheet) => {
    const timeSheetRef = timeSheet.id
      ? doc(database, 'timeSheets', timeSheet.id)
      : doc(collection(database, 'timeSheets'));

    const newTimeSheet = {
      ...timeSheet,
      lastUpdatedBy,
      lastUpdatedDate: serverTimestamp(),
    };

    if (newTimeSheet.id) {
      delete newTimeSheet.id;
    }

    batch.set(timeSheetRef, newTimeSheet);
  });

  await batch.commit();
};

export const deleteRow = async (selectedRow) => {
  const batch = writeBatch(database);

  const q = query(
    collection(database, 'timeSheets'),
    where('user.uid', '==', selectedRow.days[0].user.uid),
    where('project.id', '==', selectedRow.project.id),
    where('week', '==', Timestamp.fromDate(selectedRow.days[0].date)),
  );

  const rowDelete = await getDocs(q);
  rowDelete.forEach((doc) => batch.delete(doc.ref));
  batch.commit();
};
