import CloseIcon from '@mui/icons-material/Close';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { CircularProgress, IconButton } from '@mui/material';
import { STATUS } from '../helpers/file-upload-status';

export default function FileUploadButton({ onClick, progress, status }) {
  const isLoading = status === STATUS.LOADING || status === STATUS.PAUSED;
  let actionIcon;

  switch (status) {
    case STATUS.LOADING:
      actionIcon = <PauseIcon />;
      break;
    case STATUS.PAUSED:
      actionIcon = <PlayArrowIcon />;
      break;
    case STATUS.DONE:
      actionIcon = <CloseIcon />;
      break;
    default:
      break;
  }

  return (
    <IconButton onClick={onClick}>
      {actionIcon}
      {isLoading && (
        <CircularProgress
          color="primary"
          variant="determinate"
          value={progress}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
      )}
    </IconButton>
  );
}
