import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Icon from '../../Icon';
import './NavBarItem.css';

export default function NavBarItem({ label, icon, path, children }) {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  return children ? (
    <div
      className={`navbar-item__container${
        location.pathname.includes(path) ? ' active' : ''
      }${open ? ' open' : ''}`}
    >
      <button
        type="button"
        className="navbar-item__header"
        onClick={() => setOpen((prev) => !prev)}
      >
        <Icon className="navbar-item__icon">{icon}</Icon>
        <span className="navbar-item__label">{label}</span>
        <div className="flex-1" />
        <Icon className="navbar-item__icon navbar-item__icon--animated">
          chevron_right
        </Icon>
      </button>
      <div className="navbar-item__children">
        {children.map((child) => (
          <NavLink
            key={`${child.path}`}
            to={child.path}
            className="navbar-item navbar-item--child"
          >
            <span className="navbar-item__label">{child.label}</span>
          </NavLink>
        ))}
      </div>
    </div>
  ) : (
    <NavLink to={path} className="navbar-item">
      <Icon className="navbar-item__icon">{icon}</Icon>
      <span className="navbar-item__label">{label}</span>
    </NavLink>
  );
}
