import AddIcon from '@mui/icons-material/Add';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import FilterListIcon from '@mui/icons-material/FilterList';
import InfoIcon from '@mui/icons-material/Info';
import { Button, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { get, isEqual, unionWith } from 'lodash';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import CustomButton from '../../components/CustomButton';
import CustomTable from '../../components/CustomTable';
import Filters from '../../components/Filters';
import { useAuth } from '../../context/AuthContext';
import { useLoading } from '../../context/LoadingContext';
import { useSnackbar } from '../../context/SnackbarContext';
import projectFilters from '../../helpers/project-filters';
import { projectListColumns } from '../../helpers/project-list-columns';
import { Roles } from '../../models/user-roles.enum';
import { getActiveProjects, getProjects } from '../../services/projects';
import ProjectDetails from './ProjectDetails';

export default function ProjectsList() {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  const { currentUser } = useAuth();
  const { setLoading } = useLoading();
  const { showSnackbar } = useSnackbar();

  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        if (currentUser.role === Roles.ADMIN) {
          const fetchedProjects = await getActiveProjects();
          setProjects(fetchedProjects);
          setFilteredProjects(fetchedProjects);
        } else {
          const resourceProjects = await getProjects(
            'resources',
            'array-contains',
            currentUser.uid,
          );
          const leadProjects = await getProjects(
            'lead.uid',
            '==',
            currentUser.uid,
          );

          setProjects(unionWith(resourceProjects, leadProjects, isEqual));
          setFilteredProjects(
            unionWith(resourceProjects, leadProjects, isEqual),
          );
        }
      } catch (error) {
        console.error(error);
        showSnackbar('Hubo un error, intentalo mas tarde.', 'error');
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [currentUser]);

  const handleFilter = (filters) => {
    const newFilteredProjects = projects.filter((project) =>
      Object.entries(filters).every(([filterKey, filterValue]) => {
        const values = Array.isArray(filterValue)
          ? filterValue
          : filterValue.values;

        if (!values.length) return true;

        const projectValue = Array.isArray(filterValue)
          ? project[filterKey]
          : get(project, filterValue.field, []);

        return values.includes(projectValue);
      }),
    );

    setFilteredProjects(newFilteredProjects);
    setShowFilters(false);
  };

  const rows = filteredProjects.map((project) => {
    return {
      id: project.id,
      client: project.client,
      name: project.name,
      lead: project.lead,
      type: project.type,
      actions: (
        <>
          <Tooltip title="Detalles">
            <CustomButton
              variant="contained"
              color="primary"
              onClick={() => {
                navigate(`./${project.id}`, {
                  state: project,
                });
              }}
            >
              <InfoIcon />
            </CustomButton>
          </Tooltip>
          <Tooltip title="Estatus">
            <span>
              <CustomButton
                variant="contained"
                disabled={currentUser.role === Roles.CONSULTANT}
                color="primary"
                onClick={() =>
                  navigate(`./${project.id}/estatus`, {
                    state: { projectName: project.name },
                  })
                }
              >
                <AssignmentIcon />
              </CustomButton>
            </span>
          </Tooltip>
          <Tooltip title="Cerrar">
            <span>
              <CustomButton
                variant="contained"
                disabled={currentUser.role === Roles.CONSULTANT}
                color="error"
                onClick={() =>
                  navigate(`./${project.id}/cerrar`, {
                    state: { projectName: project.name },
                  })
                }
              >
                <CloseIcon />
              </CustomButton>
            </span>
          </Tooltip>
        </>
      ),
    };
  });
  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography sx={{ flexGrow: 1 }} variant="h4">
          Proyectos
        </Typography>
        <Button
          sx={{ marginY: 2 }}
          onClick={() => setShowFilters(true)}
          startIcon={<FilterListIcon />}
          variant="contained"
        >
          Filtros
        </Button>
        {(currentUser.role === Roles.ADMIN ||
          currentUser.role === Roles.MANAGER) && (
          <NavLink
            to="nuevo"
            element={<ProjectDetails />}
            style={{
              textDecoration: 'none',
            }}
          >
            <Button variant="contained" startIcon={<AddIcon />}>
              Nuevo Proyecto
            </Button>
          </NavLink>
        )}
      </Stack>
      <br />
      <CustomTable columns={projectListColumns} rows={rows} />
      {!filteredProjects.length && (
        <Stack
          sx={{
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            color: (theme) => theme.palette.grey[700],
          }}
          padding={2}
          component={Paper}
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <DescriptionIcon fontSize="large" />
          <Typography variant="body2">No se encontraron proyectos</Typography>
        </Stack>
      )}
      <Filters
        open={showFilters}
        config={projectFilters}
        onClose={() => setShowFilters(false)}
        onFilter={handleFilter}
      />
    </>
  );
}
