import { Roles } from '../models/user-roles.enum';

export const navbarOptions = [
  /*
  {
    label: 'Home',
    icon: 'home',
    path: '/',
  },
  Commented out "home" in the Nav Bar to disable this option while in development
  */
  {
    label: 'Junta Semanal',
    icon: 'event_repeat',
    path: 'junta_semanal',
  },
  {
    label: 'Proyectos',
    icon: 'description',
    path: 'proyectos',
  },
  {
    label: 'Horas',
    icon: 'access_time',
    path: 'horas',
    children: [
      {
        label: 'Resumen',
        path: 'horas/resumen',
        roles: [Roles.ADMIN],
      },
      {
        label: 'Registrar',
        path: 'horas/registrar',
      },
    ],
  },
  {
    label: 'Vacaciones',
    icon: 'luggage',
    path: 'vacaciones',
    children: [
      {
        label: 'Gestionar',
        path: 'vacaciones/gestionar',
      },
      {
        label: 'Calendario',
        path: 'vacaciones/calendario',
      },
      {
        label: 'Aprobar',
        path: 'vacaciones/aprobar',
        roles: [Roles.ADMIN, Roles.MANAGER, Roles.LEAD],
      },
    ],
  },
];
