import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Typography } from '@mui/material';
import { add } from 'date-fns';
import { useEffect, useState } from 'react';
import { vacationsColors } from '../../helpers/vacations-colors';
import { getVacations } from '../../services/vacations';
import { useSnackbar } from '../../context/SnackbarContext';
import { useLoading } from '../../context/LoadingContext';

export default function VacationsList() {
  const [vacations, setVacations] = useState([]);

  const { showSnackbar } = useSnackbar();
  const { setLoading } = useLoading();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const kfVacations = await getVacations('status', '==', 'Aprobadas');
        let newColor = -1;
        const calendarVacations = kfVacations.map((vacation) => {
          newColor = newColor >= 4 ? 0 : newColor + 1;
          const { color, textColor } = vacationsColors[newColor];
          return {
            title: vacation.user.name,
            start: vacation.startDate,
            end: add(vacation.endDate, { days: 1 }),
            color,
            textColor,
            allDay: true,
          };
        });
        setVacations(calendarVacations);
      } catch (error) {
        console.error(error);
        showSnackbar('Hubo un error, intentalo mas tarde.', 'error');
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  return (
    <>
      <Typography variant="h4">Vacaciones</Typography>
      <br />
      <FullCalendar
        weekends={false}
        locale="es"
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={vacations}
        buttonText={{
          today: 'Hoy',
          month: 'Mes',
          week: 'Semana',
          day: 'Día',
          list: 'Lista',
        }}
      />
    </>
  );
}
