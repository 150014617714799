import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Form, Formik } from 'formik';
import { isEqual, uniqWith } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useAuth } from '../../context/AuthContext';
import { useLoading } from '../../context/LoadingContext';
import { useSnackbar } from '../../context/SnackbarContext';
import { Roles } from '../../models/user-roles.enum';
import {
  getApprovingAdmin,
  getManager,
  getProjectLeads,
} from '../../services/users';
import { setVacation } from '../../services/vacations';

const validationSchema = yup.object({
  startDate: yup.date().required(),
  endDate: yup.date().required(),
  days: yup.number().required(),
  reason: yup.string().required(),
});

export default function EditVacations() {
  const { currentUser } = useAuth();
  const { showSnackbar } = useSnackbar();
  const { setLoading } = useLoading();
  const { vid } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [requiredApprovers, setRequiredApprovers] = useState([]);
  const initialValues = {
    ...location.state,
    startDate: location.state.startDate,
    endDate: location.state.endDate,
  };
  const { user } = location.state;

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const admin = await getApprovingAdmin();

        setRequiredApprovers([admin]);
      } catch (error) {
        console.error(error);
        showSnackbar('Hubo un error, intentalo mas tarde.', 'error');
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  const handleSubmit = async (values) => {
    const requiredApprovals = requiredApprovers.map((approver) => approver.uid);

    const approvals = requiredApprovers.map((approver) => ({
      user: approver,
      status: 'Pendiente',
    }));

    const newVacations = {
      ...values,
      requiredApprovals,
      approvals,
      status: 'Pendiente',
      user,
    };

    delete newVacations.id;

    try {
      await setVacation(newVacations, vid);
      showSnackbar('Solicitud de vacaciones editada con éxito!', 'success');
      navigate('/vacaciones/gestionar');
    } catch (error) {
      console.error(error);
      showSnackbar('Hubo un error, intentalo mas tarde.', 'error');
    }
  };

  return (
    <>
      <Typography variant="h4">Solicitud de Vacaciones</Typography>
      <Paper sx={{ width: '100', padding: 2 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ errors, touched, getFieldProps, setValues, values }) => (
            <Form>
              <Grid container columnSpacing={3} alignItems="center">
                {currentUser.role !== Roles.CONSULTANT && (
                  <Grid item xs={12}>
                    <FormControl fullWidth margin="normal">
                      <TextField
                        disabled
                        error={errors.user && touched.user}
                        label="Consultor"
                        value={user.name}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <DatePicker
                      label="Desde"
                      renderInput={(params) => <TextField {...params} />}
                      {...getFieldProps('startDate')}
                      onChange={(newValue) => {
                        setValues({ ...values, startDate: newValue });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <DatePicker
                      error={errors.endDate && touched.endDate}
                      minDate={new Date()}
                      label="Hasta"
                      renderInput={(params) => <TextField {...params} />}
                      {...getFieldProps('endDate')}
                      onChange={(newValue) => {
                        setValues({ ...values, endDate: newValue });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      type="number"
                      label="Días"
                      error={errors.days && touched.days}
                      InputProps={{ inputProps: { min: 1, max: 15 } }}
                      {...getFieldProps('days')}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="reason">Razón</InputLabel>
                    <Select
                      labelId="reason"
                      label="Razón"
                      error={errors.reason && touched.reason}
                      {...getFieldProps('reason')}
                    >
                      <MenuItem value="vacation"> Vacaciones </MenuItem>
                      <MenuItem value="kodefree"> Dias KodeFree </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <FormControl fullWidth margin="normal">
                <Stack direction="row" spacing={3}>
                  <Button
                    onClick={() => navigate('/vacaciones/gestionar')}
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    fullWidth
                  >
                    Cancelar
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    fullWidth
                  >
                    Guardar cambios
                  </Button>
                </Stack>
              </FormControl>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
}
