import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Stack,
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';

export default function Filters({ config, open, onClose, onFilter }) {
  const [filters, setFilters] = useState();

  useEffect(() => {
    const initialFilters = {};
    Object.entries(config).forEach(([key, value]) => {
      initialFilters[key] = value.field
        ? { field: value.field, values: [] }
        : [];
    });

    setFilters(initialFilters);
  }, []);

  const handleFilterChange = (event) => {
    const { checked, name, value } = event.target;

    setFilters((currentFilters) => {
      const selectedFilter = Array.isArray(currentFilters[name])
        ? [...currentFilters[name]]
        : { ...currentFilters[name] };

      if (checked) {
        return {
          ...currentFilters,
          [name]: Array.isArray(selectedFilter)
            ? [...selectedFilter, value]
            : {
                ...selectedFilter,
                values: [...selectedFilter.values, value],
              },
        };
      }

      return {
        ...currentFilters,
        [name]: Array.isArray(selectedFilter)
          ? selectedFilter.filter((filterValue) => filterValue !== value)
          : {
              ...selectedFilter,
              values: selectedFilter.values.filter(
                (filterValue) => filterValue !== value,
              ),
            },
      };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onFilter(filters);
  };

  return filters ? (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Filtros
        <IconButton
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent
        sx={{
          width: 320,
        }}
      >
        <Stack component="form" spacing={2}>
          {Object.entries(config).map(([key, filter]) => (
            <Fragment key={key}>
              <FormLabel>{filter.label}</FormLabel>
              <FormControl fullWidth>
                <FormGroup>
                  {filter.values.map((option) => (
                    <FormControlLabel
                      key={option.value || option}
                      control={
                        <Checkbox
                          value={option.value || option}
                          onChange={handleFilterChange}
                          name={key}
                          checked={
                            Array.isArray(filters[key])
                              ? filters[key].includes(option.value || option)
                              : filters[key].values.includes(
                                  option.value || option,
                                )
                          }
                        />
                      }
                      label={option.label || option}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Fragment>
          ))}
          <Button onClick={handleSubmit} type="submit" variant="contained">
            Aplicar
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  ) : null;
}
