import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LuggageIcon from '@mui/icons-material/Luggage';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { isEqual, uniqWith } from 'lodash';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CustomButton from '../../components/CustomButton';
import CustomTable from '../../components/CustomTable';
import { useAuth } from '../../context/AuthContext';
import { useLoading } from '../../context/LoadingContext';
import { useSnackbar } from '../../context/SnackbarContext';
import { vacationListColumns } from '../../helpers/vacation-list-columns';
import { Roles } from '../../models/user-roles.enum';
import { deleteVacation, getUserVacations } from '../../services/vacations';

export default function MyVacations() {
  const { currentUser } = useAuth();
  const { showSnackbar } = useSnackbar();
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  const [selectedVacation, setSelectedVacation] = useState();
  const [vacations, setVacations] = useState([]);
  const [open, setOpen] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const fetchedVacations = await getUserVacations(currentUser);
      setVacations(uniqWith(fetchedVacations, isEqual));
    } catch (error) {
      console.error(error);
      showSnackbar('Hubo un error, intentalo mas tarde.', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClickDelete = (vacation) => {
    setSelectedVacation(vacation);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      await deleteVacation(selectedVacation.id);
      fetchData();
      showSnackbar('Se han borrado las vacaciones con exito!', 'success');
    } catch (error) {
      console.error(error);
      showSnackbar('Hubo un error, intentalo mas tarde.', 'error');
    } finally {
      setSelectedVacation();
      setOpen(false);
    }
  };

  const rows = vacations.map((vacation) => {
    const startDate = format(vacation.startDate, 'PPP', {
      locale: es,
    });
    const endDate = format(vacation.endDate, 'PPP', { locale: es });

    const row = {
      id: vacation.id,
      startDate,
      endDate,
      days: `${vacation.days.toString()} dia${vacation.days > 1 ? 's' : ''}`,
      status:
        vacation.status !== 'Pendiente' ? (
          vacation.status
        ) : (
          <Tooltip
            sx={{
              textDecoration: 'underline',
              textDecorationStyle: 'dotted',
              cursor: 'help',
            }}
            title={
              <>
                {vacation.approvals.map((approval) => (
                  <Typography
                    key={`${vacation.id}_${approval.user.uid}`}
                    variant="body2"
                  >
                    {approval.user.name} - {approval.status}
                  </Typography>
                ))}
              </>
            }
            placement="top"
          >
            <Typography>{vacation.status}</Typography>
          </Tooltip>
        ),
      actions: (
        <>
          <Tooltip title="Editar">
            <CustomButton
              variant="contained"
              color="primary"
              onClick={() => {
                navigate(`/vacaciones/${vacation.id}`, {
                  state: vacation,
                });
              }}
            >
              <EditIcon />
            </CustomButton>
          </Tooltip>
          <Tooltip title="Borrar">
            <CustomButton
              variant="contained"
              color="error"
              onClick={() => handleClickDelete(vacation)}
            >
              <DeleteIcon />
            </CustomButton>
          </Tooltip>
        </>
      ),
    };

    if (currentUser.role !== Roles.CONSULTANT) {
      return { user: vacation.user.name, ...row };
    }

    return row;
  });

  const columns =
    currentUser.role === Roles.CONSULTANT
      ? vacationListColumns.filter((column) => column.field !== 'user')
      : vacationListColumns;

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h4">
          {currentUser.role === Roles.CONSULTANT
            ? 'Mis Vacaciones'
            : 'Gestionar Vacaciones'}
        </Typography>

        <Button
          component={Link}
          to="/vacaciones/nuevas"
          variant="contained"
          startIcon={<AddIcon />}
        >
          Nueva Solicitud
        </Button>
      </Stack>

      <br />
      {vacations.length ? (
        <CustomTable columns={columns} rows={rows} />
      ) : (
        <Stack
          sx={{
            color: (theme) => theme.palette.grey[700],
          }}
          padding={10}
          component={Paper}
          alignItems="center"
          justifyContent="center"
          spacing={4}
        >
          <LuggageIcon sx={{ fontSize: 80 }} />
          <Typography variant="body">No se encontraron vacaciones</Typography>
        </Stack>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Borrar Vacaciones</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Esta operación es permanente. ¿Estás seguro que deseas continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            onClick={handleDelete}
            startIcon={<DeleteIcon />}
            variant="contained"
            color="error"
          >
            Borrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
