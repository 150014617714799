import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Paper, Stack, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';
import CustomButton from '../../components/CustomButton';
import CustomTable from '../../components/CustomTable';
import { useSnackbar } from '../../context/SnackbarContext';
import { authorizeVacationListColumns } from '../../helpers/authorize-vacation-list-columns';
import {
  onPendingVacationsSnapshot,
  updateVacation,
} from '../../services/vacations';

export default function AuthorizeVacations() {
  const { showSnackbar } = useSnackbar();
  const { currentUser } = getAuth();
  const [vacations, setVacations] = useState([]);

  useEffect(() => {
    const unsubscribe = onPendingVacationsSnapshot(
      currentUser.uid,
      (snapshot) => {
        const fetchedVacations = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            id: doc.id,
            startDate: data.startDate.toDate(),
            endDate: data.endDate?.toDate(),
          };
        });

        const filteredVacations = fetchedVacations.filter((vacation) =>
          vacation.approvals.some((approval) => {
            return (
              approval.user.uid === currentUser.uid &&
              approval.status === 'Pendiente'
            );
          }),
        );

        setVacations(filteredVacations);
      },
    );

    return unsubscribe;
  }, []);

  const handleApprove = async (vacation) => {
    const approvals = vacation.approvals.map((approval) =>
      approval.user.uid === currentUser.uid
        ? { ...approval, status: 'Aprobadas' }
        : approval,
    );

    const allApproved = approvals.every(
      (approval) => approval.status === 'Aprobadas',
    );

    const payload = {
      approvals,
    };

    if (allApproved) {
      payload.status = 'Aprobadas';
    }

    try {
      await updateVacation(vacation.id, payload);
      showSnackbar('Se han aprobado las vacaciones con exito!', 'success');
    } catch (error) {
      console.error(error);
      showSnackbar('Hubo un error, intentalo mas tarde.', 'error');
    }
  };

  const handleReject = async (vacation) => {
    const approvals = vacation.approvals.map((approval) =>
      approval.user.uid === currentUser.uid
        ? { ...approval, status: 'Rechazadas' }
        : approval,
    );

    const payload = {
      approvals,
      status: 'Rechazadas',
    };

    try {
      await updateVacation(vacation.id, payload);
      showSnackbar('Solicitud de vacaciones creada con éxito!', 'success');
    } catch (error) {
      console.error(error);
      showSnackbar('Hubo un error, intentalo mas tarde.', 'error');
    }
  };

  const rows = vacations.map((vacation) => {
    const startDate = format(vacation.startDate, 'PPP', {
      locale: es,
    });
    const endDate = format(vacation.endDate, 'PPP', { locale: es });

    return {
      id: vacation.id,
      user: vacation.user,
      startDate,
      endDate,
      days: `${vacation.days.toString()} dia${vacation.days > 1 ? 's' : ''}`,
      actions: (
        <>
          <Tooltip title="Aprobar">
            <CustomButton
              variant="contained"
              color="success"
              onClick={() => handleApprove(vacation)}
            >
              <DoneIcon />
            </CustomButton>
          </Tooltip>
          <Tooltip title="Rechazar">
            <CustomButton
              variant="contained"
              color="error"
              onClick={() => handleReject(vacation)}
            >
              <CloseIcon />
            </CustomButton>
          </Tooltip>
        </>
      ),
    };
  });

  return (
    <>
      <Typography variant="h4">Aprobar Vacaciones</Typography>
      <br />
      {vacations.length ? (
        <CustomTable columns={authorizeVacationListColumns} rows={rows} />
      ) : (
        <Stack
          sx={{
            color: (theme) => theme.palette.grey[700],
          }}
          padding={10}
          component={Paper}
          alignItems="center"
          justifyContent="center"
          spacing={4}
        >
          <CheckCircleIcon sx={{ fontSize: 80 }} color="success" />
          <Typography variant="body">No hay vacaciones que aprobar</Typography>
        </Stack>
      )}
    </>
  );
}
