import { createColumnHelper } from '@tanstack/table-core';
import { isSameWeek } from 'date-fns';
import React, { useMemo } from 'react';
import Loader from '../../../components/Loader';
import Status from '../../../components/Status';
import Table from '../../../components/Table/Table';
import { useSnackbar } from '../../../context/SnackbarContext';
import { StatusSeverity } from '../../../models/status.enum';
import { useActiveProjects } from '../../../services/projects';

export default function Projects({ week }) {
  const { showSnackbar } = useSnackbar();

  const columnHelper = createColumnHelper();
  const columns = useMemo(
    () => [
      columnHelper.accessor('client', {
        cell: (info) => info.getValue(),
        header: 'Cliente',
      }),
      columnHelper.accessor('name', {
        cell: (info) => info.getValue(),
        header: 'Proyecto',
      }),
      columnHelper.accessor('lead', {
        cell: (info) => info.getValue(),
        header: 'Lider',
      }),
      columnHelper.accessor('type', {
        cell: (info) => info.getValue(),
        header: 'Tipo',
      }),
      columnHelper.accessor('status', {
        cell: (info) => {
          const status = info.getValue();
          return isSameWeek(week, status?.week) ? Status(status) : null;
        },
        header: 'Estatus',
        sortUndefined: true,
        sortingFn: (rowA, rowB, columnId) => {
          if (
            !isSameWeek(rowA.getValue(columnId)?.week, week) ||
            !isSameWeek(rowB.getValue(columnId)?.week, week)
          ) {
            return 1;
          }
          return (
            StatusSeverity[rowA.getValue(columnId).color] -
            StatusSeverity[rowB.getValue(columnId).color]
          );
        },
      }),
    ],
    [],
  );

  const [projects, loading, error] = useActiveProjects();

  if (error) {
    console.error(error);
    showSnackbar('Hubo un error, intentalo mas tarde.', 'error');
  }

  const data = projects.map((project) => ({
    client: project.client,
    name: project.name,
    lead: project.lead.name,
    type: project.type,
    status: project.currentStatus,
  }));

  return (
    <>
      {loading ? <Loader /> : null}
      <Table data={data} columns={columns} enableSorting enableFilter />
    </>
  );
}
